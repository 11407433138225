<template>
    <div>

        <Quote/>

        <div class="course-title-box">
            <p class="course-title pt-5 col-10 fadeIn">Junior Full Stack Web Developer</p>
            <p class="course-subtitle mt-4 pb-4 col-10 fadeIn">
                Video darslarimiz orqali dasturlashni mustaqil, bepul o’rganishingiz mumkin
            </p>
        </div>

        <div class="technologies pt-5">

            <div class="text-center ps-3 pe-3 mb-5">
                <h4>
                    Bepul videolarni o'rganish qoidalari bor. Ular
                    <a href="https://myblog.uz/2021/02/01/dasturlashni-organayotganda-kop-uchraydigan-savollarga-javob/"
                       target="_blank"
                       class="hover-decoration"
                    >
                        ushbu
                    </a>
                    maqolada ko'rsatilgan.
                </h4>

            </div>

            <div class="row d-sm-flex mb-5 slideInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="image12"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">

                    <h2 class="px-5 m-0">Umumiy dasturlash teoriyasi</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        O’quv markazlari odatda ma’lum bir dasturlash tilini o’rgatishadi holos. Men esa
                        kursning 3 haftasini dasturlash asosini o’rgatishga sarflayman. Bu bilim bilan siz xohlagan
                        dasturlash tilida be’malol dasturlar yoza oladigan bo’lasiz.
                    </p>
                    <p class="px-4 mx-4 m-0">
                        O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/playlist?list=PLcvajZ_CZX8RygFD05lSHujpk2gLcBQaO"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 slideInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="PHP"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="px-5 m-0">PHP</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        Hozirgi internetlashgan zamonda bu til eng muhim daturlash tillaridan biri hisoblanadi.
                        Ma’lumot uchun: internetdagi web dasturlar va saytlarning 80% shu tilda yozilgan. Shu jumladan
                        Facebook ham aynan shu tilda yozilgan.
                    </p>
                    <p class="px-4 mx-4 m-0">
                        O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=btN1yCxrF2w&list=PLcvajZ_CZX8SnaN7iyS1kF5zPF2zkofi6&index=4"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 slideInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="JS"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="px-5 m-0">JavaScript</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        Ushbu til brauzerlar uchun ishlab chiqilgan bo’lib, hozirgi kunda qariyib barcha
                        dasturlash yo’nalishlarida keng qo’llaniladi. Brauzerlarda yagona dasturlash tili hisoblanadi.
                        Hozir mobil dasturlarni ham ushbu tilda yozish urf bo’lmoqda.
                    </p>
                    <p class="px-4 mx-4 m-0">O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=rXUT0bVtl2U&list=PLcvajZ_CZX8SnaN7iyS1kF5zPF2zkofi6&index=8"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 slideInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="HTML-CSS"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="px-5 m-0">HTML va CSS</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        Bu tillar dasturlash tillariga kirmaydi, ular veb-dasturlarning tashqi ko’rinishini
                        ifodalash uchun ishlatiladi.
                    </p>
                    <p class="px-4 mx-4 m-0">O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=M2gaVOA29sk&list=PLcvajZ_CZX8R9SdDlvsV5wSk198JiM_Kj"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 slideInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Bootstrap"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="px-5 m-0">Bootstrap</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        Ushbu freymvork – dasturning tashqi ko’rinishi uchun kuchli qurol hisoblanadi. HTML,
                        CSS va JavaScript larning tayyor qoliplarini jamlagan. Siz tashqi ko’rinish uchun ko’p kod
                        yozmasdan, tayyor chiroyli bootstrap elementlaridan foydalanishingiz mumkin.
                    </p>
                    <p class="px-4 mx-4 m-0">
                        O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=keFQLjWhgpw&list=PLcvajZ_CZX8R9SdDlvsV5wSk198JiM_Kj&index=5"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 slideInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center p-0 m-0">
                    <ImgTag image="Linux"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 p-0 m-0">
                    <h2 class="px-5 m-0">Linux</h2>
                    <p class="px-4 px-sm-4  mx-4 align p-0 m-0">
                        Linux terminalida ishlashni o’rganasiz. Afsuski Windows foydalanuvchilari ushbu asosiy
                        buyruqlarni bilishmaydi. Hozirgi kunda deyarli barcha ish beruvchilar, dasturchilar
                        asosiy linux buyruqlarini bilishi kerak deb hisoblashadi.
                    </p>
                    <p class="px-4 mx-4 m-0">
                        O'rganishni boshlash uchun
                        <a
                            class="hover-decoration"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=seuAw4W2Q7w&list=PLcvajZ_CZX8SnaN7iyS1kF5zPF2zkofi6&index=12"
                        >
                            bu yerga
                        </a>
                        bosing.
                    </p>
                </div>
            </div>

            <div class="course-title-box mb-5 slideInLeft">
                <p class="course-title pt-5 col-10">
                    Hamma dasturlashni o’rganishi shart.<br> Chunki u sizga o’ylashni o’rgatadi
                </p>
                <p class="course-subtitle mt-4 pb-5 col-10">© Steve Jobs</p>
            </div>

            <h4 class="text-center">
                Agar ustoz bilan o'rganmoqchi bo'lsangiz, siz uchun maxsus
                <router-link to="/course" @click.native="scrollToTop" class="hover-decoration">kurslarimiz</router-link>
                bor.
            </h4>

            <!--            <div class="Tech mb-5" style="height: auto">-->
            <!--                <div class="row heading col-11">-->
            <!--                    <div>-->
            <!--                        <h1>Pullik o’rganing: Junior Full Stack Web Developer</h1>-->
            <!--                        <p>Video darslarimiz orqali dasturlashni mustaqil, bepul o’rganishingiz mumkin</p>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="U.D.T"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="JavaScript"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTML"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="CSS"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Bootstrap"/>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="MySQL"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Api-Platform"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Vue.js"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Git"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux CLI"/>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="phpStorm"/>-->
            <!--                        <div class="col-sm-0 col-lg-8"/>-->
            <!--                    </div>-->
            <!--                    <router-link cl class="link" :to="'/course'">batafsil -></router-link>-->
            <!--                </div>-->
            <!--            </div>-->

            <!--            <div class="Tech" style="height: auto">-->
            <!--                <div class="row heading col-11">-->
            <!--                    <div>-->
            <!--                        <h1>Pullik o’rganing: Middle Full Stack Web Developer</h1>-->
            <!--                        <p>Buning uchun Junior Full Stack Web Developer <br>kursini tugatgan bo’lishingiz kerak</p>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Docker"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="VDS"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SSH"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux OS"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Apt-Get"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Cron"/>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Gitlab CI_DI"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SOLID"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Redis"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Rabbit MQ"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Jobs & Workers"/>-->
            <!--                    </div>-->
            <!--                    <div class="row justify-content-center p-0">-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Regular Expression"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTTP Client"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Parsing"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP Traits"/>-->
            <!--                        <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony Advanced"/>-->
            <!--                        <div class="col-sm-0 col-lg-0 col-xl-2"/>-->
            <!--                    </div>-->
            <!--                    <router-link class="link" :to="'/course-middle'">batafsil-></router-link>-->
            <!--                </div>-->
            <!--            </div>-->

        </div>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import ImgTag from "@/components/html/ImgTag";

export default {
    name: "FreeCourse",
    components: {ImgTag, Quote},
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

.slideInLeft {
    animation-name: slideInLeft;
    animation-duration: 1s;
}

.slideInRight {
    animation-name: slideInRight;
    animation-duration: 1s;
}

.fadeIn {
    animation: fadeIn;
    animation-duration: 1s;
}

.p1 {
    padding-top: 43px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: justify;
}

.heading {
    color: #FFFFFF;
    padding: 0 !important;
}

.heading p {
    margin: 18px 0 42px;
}

.link {
    float: right;
    color: #FFFFFF;
    font-weight: 600;
    text-align: end;
}

.h21 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
}

.course-title-box {
    color: #fff;
    background-color: #80007F;
    min-height: 164px;
    width: 100%;
}

.course-title-box p,
.heading {
    margin: auto;
}

.course-title {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    padding: 0;
}

.course-subtitle {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding: 0;
}

.align {
    text-align: justify;
}

.senter h5 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    padding-left: 12.5%;
    padding-right: 10%;
    padding-top: 85px;
    padding-bottom: 15px;
}

.senter h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    margin: 30px 0;
}

.sent h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24.9761px;
    line-height: 28px;
    margin: 30px 0;
}

.divp6 li {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 15px;
}

.Tech {
    font-family: Comfortaa, cursive;
    font-style: normal;
    font-weight: normal;
    background: #80007F;
    width: 100%;
    padding: 30px 69px 40px 69px;
    height: auto;

}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

</style>
